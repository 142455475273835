import React, { useState } from 'react'
import { Typography, Box, Card, Switch, Link, Tooltip, Snackbar, Alert, SxProps, Dialog, DialogContent, DialogActions, Button, IconButton, CircularProgress } from '@mui/material'
import { CalendarEvent } from '../../client'
import { gSx, gSxText } from '../../styles/Theme'
import { UpdateCalendarMeeting } from '../../service/calendarApi'
import Grid from '@mui/material/Unstable_Grid2'
import useIsBlockedByPlan from 'hooks/useIsBlockedByPlan'
import CheckIcon from '@mui/icons-material/Check'
import { Media } from 'service/media'
import EventNoteIcon from '@mui/icons-material/EventNote'

interface CalEventProp {
  calEvent: CalendarEvent
  isSmall?: boolean
}

export default function CalendarEventSimple({ calEvent, isSmall }: CalEventProp) {
  const time = (new Date(calEvent.start_time ?? '')).toLocaleTimeString()
  const [record, setRecord] = useState(calEvent.will_record)
  const { isBlocked } = useIsBlockedByPlan()
  const [err, setErr] = useState<string | undefined>()
  const [busy, setBusy] = useState(false)
  const [isSnackSuccess, setSnackSuccess] = useState(false)
  const [showPremeeting, setShowPremeeting] = useState(false)

  async function onRecord(switchElement: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    setErr(undefined)
    setRecord(checked)
    setBusy(true)

    calEvent.override_should_record = checked
    const res = await UpdateCalendarMeeting(calEvent)
    if (res instanceof Error) {
      setErr(res.message)
    } else {
      setSnackSuccess(true)
    }
    setBusy(false)
  }
  function onShowPremeeting() {
    setShowPremeeting(true)
  }

  function onClosePremeeting() {
    setShowPremeeting(false)
  }
  const url = calEvent.meeting_url
  const disableRecord = isBlocked || !url || url?.length == 0 || isPastStartTime(calEvent)

  return (
    <Card>
      <Grid container>
        <Grid xs={isSmall ? 8 : 12} md={isSmall ? 8 : 6}>
          <Typography variant='h6'>{calEvent.title}</Typography>
          <Typography>{time}</Typography>
          <Link rel="noopener" target="_blank" href={url}>{url}</Link>
        </Grid>

        <Grid xs={isSmall ? 4 : 12} md={4}>
          <Typography variant='h6'>Attendees</Typography>
          {calEvent.attendees?.map(a =>
            <Typography key={a.email}>{a.email ?? a.name}{a.is_organizer && ' (host)'}</Typography>
          )}
        </Grid>

        <Grid xs={12} md={isSmall ? 12 : 2} sx={sxRightColumn}>

          <Box>
            <Tooltip title={isBlocked ? 'Requires Starter or Standard Plan' : "Schedule Recording"}>
              <Box sx={gSx.Row}>
                {busy
                  ? <CircularProgress size={20} sx={{ paddingX: 2.35, paddingY: 1.15 }} />
                  : <Switch
                    checked={record}
                    onChange={onRecord}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={disableRecord}
                  />
                }

                <Typography sx={gSxText.small}>Record</Typography>
              </Box>
            </Tooltip>
          </Box>
          <Box sx={sxBadge}>
            {calEvent.is_redtail &&
              <Tooltip title="Redtail Calendar">
                <Box
                  component="img"
                  sx={sxIcon}
                  src={Media.redtailIconWhite}
                  alt="Redtail"
                />
              </Tooltip>
            }
            {calEvent.is_wealthbox &&
              <Tooltip title="Wealthbox Calendar">
                <Box
                  component="img"
                  sx={sxIcon}
                  src={Media.wealthboxIcon}
                  alt="Wealthbox"
                />
              </Tooltip>
            }
            {calEvent.premeeting_html &&
              <Tooltip title="Pre-meeting Note">
                <IconButton onClick={onShowPremeeting} sx={sxIcon}><EventNoteIcon /></IconButton>
              </Tooltip>
            }
          </Box>

          {err && <Typography color={'tomato'} >{err}</Typography>}
        </Grid >
      </Grid >

      <Snackbar
        open={isSnackSuccess}
        autoHideDuration={3000}
        onClose={() => setSnackSuccess(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          variant="filled"
          icon={<CheckIcon fontSize="inherit" />}
          onClose={() => setSnackSuccess(false)}
          severity='success'
          sx={{ width: '100%' }}
        >
          Record Preference Updated Succesfully
        </Alert>
      </Snackbar>
      <Dialog open={showPremeeting} fullWidth maxWidth="md">
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{ __html: calEvent.premeeting_html ?? "" }}
            style={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px',
              padding: '16px',
              overflow: 'auto',
              backgroundColor: '#fcfcfc',
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosePremeeting} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card >
  )
}

function isPastStartTime(event: CalendarEvent) {
  if (!event.start_time)
    return false
  const start = new Date(event?.start_time)
  return start <= new Date()
}

const sxIcon: SxProps = {
  width: 25,
  height: 25,
}

const sxRightColumn: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

const sxBadge: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
}