import React from 'react'
import { Typography, Box, IconButton, Tooltip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import 'firebase/auth'
import { Path } from 'routes'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { gSx } from 'styles/Theme'

export default function RedtailAutoJoinNotice() {
  const navigate = useNavigate()

  return (
    <Box sx={gSx.Row}>
      <Typography>
        {"Redtail calendar meetings synced. Auto join applied."}
      </Typography>
      <Tooltip title='Learn More'>
        <IconButton onClick={() => navigate(Path.RedtailSettings)}>
          <InfoOutlinedIcon />
        </IconButton>
      </Tooltip>
    </Box>
  )
}
