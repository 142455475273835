import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Typography, FormControl, Select, MenuItem, SelectChangeEvent, Divider } from '@mui/material'
import 'firebase/auth'
import { FlowInput, FlowInputConfig, SalesforceFlowTextEnum } from 'client'
import Grid from '@mui/material/Unstable_Grid2'

interface Props {
  input: FlowInput
  setFlowInputs: Dispatch<SetStateAction<FlowInputConfig[]>>
  isTranscriptValid: boolean
}

export default function SalesforceFlowText({ input, setFlowInputs, isTranscriptValid }: Props) {
  const [selected, setSelected] = useState<SalesforceFlowTextEnum>(SalesforceFlowTextEnum.MEETING_TITLE)

  const isArray = !!(input.maxOccurs && input.maxOccurs > 1)

  useEffect(() => {
    if (!isArray) {
      setSelected(SalesforceFlowTextEnum.MEETING_TITLE) // init
      const newFlowInput: FlowInputConfig = { input, text: SalesforceFlowTextEnum.MEETING_TITLE }
      setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
    }
  }, [])

  const onChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as SalesforceFlowTextEnum
    setSelected(value)
    const newFlowInput: FlowInputConfig = { input }
    newFlowInput.text = value
    setFlowInputs(prev => [...prev.filter(o => o.input !== input), newFlowInput])
  }

  return (
    <Grid container>
      <Grid xs={4}>
        <Typography>{input.name}</Typography>
      </Grid>
      <Grid xs={8}>

        {
          isArray ? (
            <Typography>Not Available. Please Contact us to assist in configuration</Typography>
          ) : (
            <FormControl fullWidth>
              <Select
                value={selected}
                onChange={onChange}
                defaultValue='notes'
              >
                <MenuItem value={SalesforceFlowTextEnum.MEETING_TITLE}>{SalesforceFlowTextEnum.MEETING_TITLE}</MenuItem>

                <MenuItem value={SalesforceFlowTextEnum.SUMMARY_NOTES_HTML_}>{SalesforceFlowTextEnum.SUMMARY_NOTES_HTML_}</MenuItem>

                <MenuItem value={SalesforceFlowTextEnum.SUMMARY_NOTES_PLAIN_TEXT_}>{SalesforceFlowTextEnum.SUMMARY_NOTES_PLAIN_TEXT_}</MenuItem>

                {
                  isTranscriptValid &&
                  <>
                    <MenuItem value={SalesforceFlowTextEnum.TRANSCRIPT_HTML_}>{SalesforceFlowTextEnum.TRANSCRIPT_HTML_}</MenuItem>

                    <MenuItem value={SalesforceFlowTextEnum.TRANSCRIPT_PLAIN_TEXT_}>{SalesforceFlowTextEnum.TRANSCRIPT_PLAIN_TEXT_}</MenuItem>
                  </>
                }

              </Select>
            </FormControl>
          )
        }

      </Grid>
      <Grid xs={12}>
        <Divider sx={{ marginY: 1 }} />
      </Grid>
    </Grid>
  )
}